import { CallAPI } from 'actions/General';
import { cloneDeep, groupBy, sortBy } from 'lodash';
import { uploadImage } from 'pages/company/utils';

export const createAPIStructure = (formProperties) => {
  let errorsArray = [];
  let formPageKeys = Object.keys(formProperties);
  let elements = [];
  let supervisorElements = [];
  let statusElements = [];
  let position = 1;
  let page = 1;
  let fieldUniqueIdsList = [];
  formPageKeys.map((singlePageKey) => {
    if (singlePageKey !== 'otherDetails') {
      let pageElementPosition = 0;
      let sectionNumber = 0;
      let singlePageElements = cloneDeep(formProperties[singlePageKey].elements);
      let pageErrors = [];
      singlePageElements.map((singleElement) => {
        if (singleElement.elementType === 'section') sectionNumber++;

        // Create Single Element for API
        if (singlePageKey === 'supervisorFields') {
          let elementType = singleElement.elementType.replace('supervisor_', '');
          if (elementType === 'status')
            statusElements.push({
              ...singleElement,
              position,
              pageNumber: 0,
              sectionNumber: 0,
              elementType: elementType,
              properties: singleElement.properties,
            });
          else
            supervisorElements.push({
              ...singleElement,
              position,
              pageNumber: 0,
              sectionNumber: 0,
              elementType: elementType,
              properties: singleElement.properties,
            });
        } else {
          if (singleElement.elementType === 'checkList') {
            if (singleElement.triggers) {
              console.log(singleElement.triggers);
              const triggersObj = createGroupedTriggersObject(singleElement.triggers);
              if (Object.keys(triggersObj).length === 0) delete singleElement.triggers;
              else singleElement.triggers = cloneDeep(triggersObj);
              console.log(singleElement);
            }
          }
          if (singleElement.elementType === 'dataSource') {
            let updatedBindingList = singleElement.properties.basicProperties.bindingList.filter(
              (singleBindElement) => {
                if (singleBindElement) {
                  return singleBindElement;
                }
              },
            );
            singleElement.properties.basicProperties.bindingList = updatedBindingList;
          }
          if (singleElement.childReferenceId) delete singleElement.childReferenceId;
          elements.push({
            ...singleElement,
            position,
            pageNumber: page,
            sectionNumber,
            properties: singleElement.properties,
          });
        }
        getValidationError(
          fieldUniqueIdsList,
          cloneDeep(singleElement),
          pageElementPosition,
          pageErrors,
        );
        position++;
        pageElementPosition++;
      });
      if (pageErrors.length) {
        const errorsClassification =
          singlePageKey === 'supervisorFields'
            ? ['form_error.Supervisors fields errors']
            : ['form_error.Page (', `${page}`, 'form_error.) errors'];
        errorsArray.push({ errorsClassification, errorList: pageErrors });
      }
      page++;
    }
  });

  // Check Duplicate Field Unique Key values
  const fieldUniqueIdsDuplicated =
    new Set([...fieldUniqueIdsList]).size !== fieldUniqueIdsList.length;
  if (fieldUniqueIdsDuplicated) {
    const errorsClassification = ['form_error.Duplicate IDs'];
    const errorList = [['form_error.Duplicate field IDs']];

    errorsArray.push({ errorsClassification, errorList });
  }
  return {
    status: !errorsArray.length,
    errors: errorsArray,
    supervisorElements,
    elements,
    statusElements,
  };
};

const createGroupedTriggersObject = (triggers) => {
  const level1Grouped = groupBy(
    triggers.filter((t) => t.types.length),
    (trigger) => trigger.matcher,
  );

  for (const level1Key in level1Grouped) {
    const level2Grouped = Object.fromEntries(
      level1Grouped[level1Key].map((l) => {
        const types = l.types.map((t) => ({
          type: t.type,
          ...(t.type === 'ask_a_question' && {
            elementGlobalId: t.elementGlobalId ?? null,
            triggerFlowReferenceId: t.triggerFlowReferenceId ?? null,
          }),
        }));
        return [l.matchingValue, types];
      }),
    );

    level1Grouped[level1Key] = level2Grouped;
  }
  return level1Grouped;
};

export const createUngroupedTriggersArray = (level1Grouped) => {
  const triggerArr = [];
  const childIds = [];

  for (const level1Key in level1Grouped) {
    for (const level2Key in level1Grouped[level1Key]) {
      const types = [];
      for (const t of level1Grouped[level1Key][level2Key]) {
        if (t.elementGlobalId) childIds.push(t.elementGlobalId);
        types.push({
          type: t.type,
          ...(t.type === 'ask_a_question' && {
            elementGlobalId: t.elementGlobalId,
            triggerFlowReferenceId: null,
            childReferenceId: t.elementGlobalId,
          }),
        });
      }

      console.log(types);

      triggerArr.push({ matcher: level1Key, matchingValue: level2Key, types: types });
    }
  }
  return { triggerArr, childIds };
};

export const swapFieldAndSectionElements = (currentPageElements, position, action) => {
  if (action === 'increase') {
    let moveByPosition = position + 1;
    let movingCount = 1;
    let moveByCount = 1;

    for (let i = position + 1; i < currentPageElements.length; i++) {
      if (!currentPageElements[i].childReferenceId) {
        moveByPosition = i;
        break;
      }
      movingCount++;
    }

    for (let i = moveByPosition + 1; i < currentPageElements.length; i++) {
      if (!currentPageElements[i].childReferenceId) break;
      moveByCount++;
    }

    const spliced = currentPageElements.splice(position, movingCount);
    currentPageElements.splice(position + moveByCount, 0, ...spliced);
  } else if (action === 'decrease') {
    let moveByPosition = position - 1;
    let movingCount = 1;

    for (let i = position + 1; i < currentPageElements.length; i++) {
      if (!currentPageElements[i].childReferenceId) break;
      movingCount++;
    }

    for (let i = position - 1; i > 0; i--) {
      if (!currentPageElements[i].childReferenceId) {
        moveByPosition = i;
        break;
      }
    }

    const spliced = currentPageElements.splice(position, movingCount);
    currentPageElements.splice(moveByPosition, 0, ...spliced);
  }
};

export const removeFieldWithChildElements = (currentPageElements, position) => {
  let deletionCount = 1;
  const deletedFieldId =
    currentPageElements[position].elementGlobalId ??
    currentPageElements[position].uniqueIdToReplace ??
    null;
  for (let i = position; i < currentPageElements.length; i++) {
    if (!currentPageElements[i + 1] || !currentPageElements[i + 1].childReferenceId) break;
    deletionCount++;
  }
  currentPageElements.splice(position, deletionCount);
  return deletedFieldId;
};

export const removeSectionWithChildElements = (currentPageElements, position, removedElements) => {
  let deletionCount = 1;
  for (let i = position; i < currentPageElements.length; i++) {
    removedElements.push(currentPageElements[i]);
    if (!currentPageElements[i + 1] || currentPageElements[i + 1].elementType === 'section') break;
    deletionCount++;
  }
  currentPageElements.splice(position, deletionCount);
};

export const swapPageElements = (properties, detail, action) => {
  const replacedPageNumber = detail.pageNumber + (action === 'increase' ? 1 : -1);
  const replacingPageElements = properties['page' + detail.pageNumber].elements;

  properties['page' + detail.pageNumber].elements =
    properties['page' + replacedPageNumber].elements;
  properties['page' + replacedPageNumber].elements = replacingPageElements;
};

export const removePageElementWithChildElements = (
  properties,
  detail,
  currentPage,
  setCurrentPage,
  setTotalPages,
  removedElements,
) => {
  removedElements = properties['page' + detail.pageNumber].elements;

  if (detail.pageNumber === detail.totalPages) {
    delete properties['page' + detail.pageNumber];
  } else {
    for (let index = detail.pageNumber; index < detail.totalPages; index++) {
      properties['page' + index].elements = properties['page' + (index + 1)].elements;
    }
    delete properties['page' + detail.totalPages];
  }

  if (detail.pageNumber < currentPage) setCurrentPage(currentPage - 1);
  setTotalPages((totalPages) => totalPages - 1);

  return removedElements;
};

export const jsonStringifyAllElements = (APIData) => {
  for (let index = 0; index < APIData.elements.length; index++) {
    const element = APIData.elements[index];
    element.properties = JSON.stringify(element.properties);
  }
  for (let index = 0; index < APIData.supervisorElements.length; index++) {
    const element = APIData.supervisorElements[index];
    element.properties = JSON.stringify(element.properties);
  }
  for (let index = 0; index < APIData.statusElements.length; index++) {
    const element = APIData.statusElements[index];
    element.properties = JSON.stringify(element.properties);
  }
  return APIData;
};
export const removeUnbindElementUniqueIds = (elements, formProperties) => {
  elements.map((singleElement) => {
    if (
      singleElement.uniqueIdToReplace &&
      !formProperties.otherDetails.bindingElementsObj[singleElement.uniqueIdToReplace]
    ) {
      delete singleElement.uniqueIdToReplace;
    }
  });
  return elements;
};

const getValidationError = (fieldUniqueIdsList, singleElement, pageElementPosition, pageErrors) => {
  // Check Title and Field Unique Key That these must be added except Image Element
  if (singleElement.elementType !== 'image') {
    let key =
      singleElement.elementType === 'page'
        ? 'pageTitle'
        : singleElement.elementType === 'section'
        ? 'sectionTitle'
        : 'fieldTitle';
    // Check Title is not empty
    if (singleElement.properties.basicProperties[key] === '') {
      if (singleElement.elementType === 'page')
        pageErrors.push(['form_error.Page has missing title']);
      else
        pageErrors.push([
          'form_error.Element (',
          `${pageElementPosition}`,
          'form_error.) has missing title',
        ]);
    }
    // Check field Unique Id Exist, Add in array to check unique
    if (
      singleElement.properties.otherProperties &&
      singleElement.properties.otherProperties.fieldUniqueId &&
      singleElement.properties.otherProperties.fieldUniqueId !== ''
    ) {
      fieldUniqueIdsList.push(singleElement.properties.otherProperties.fieldUniqueId);
    }

    // Check Empty Field Unique Key error
    if (
      singleElement.elementType !== 'page' &&
      singleElement.elementType !== 'section' &&
      singleElement.properties.otherProperties.fieldUniqueId === ''
    ) {
      pageErrors.push([
        'form_error.Element (',
        `${pageElementPosition}`,
        'form_error.) has missing field unique id',
      ]);
    }

    // Check Data Source Related Selected keys
    if (singleElement.elementType === 'dataSource') {
      if (singleElement.properties.basicProperties.dataSource === '') {
        pageErrors.push([
          'form_error.Element (',
          `${pageElementPosition}`,
          'form_error.) has missing data source options',
        ]);
      }
      if (singleElement.properties.basicProperties.displayColumn === '') {
        pageErrors.push([
          'form_error.Element (',
          `${pageElementPosition}`,
          'form_error.) has missing data source display column',
        ]);
      }
      if (singleElement.properties.basicProperties.valueColumn === '') {
        pageErrors.push([
          'form_error.Element (',
          `${pageElementPosition}`,
          'form_error.) has missing data source value column',
        ]);
      }
    }

    // CheckList, Status Related Mandatory Options
    if (
      ['checkList', 'status'].includes(singleElement.elementType) &&
      singleElement.properties.basicProperties.optionList.length < 2
    ) {
      pageErrors.push([
        'form_error.Element (',
        `${pageElementPosition}`,
        'form_error.) has missing answer choices',
      ]);
    }
  } else if (
    !singleElement.properties.layoutProperties.fieldImage?.id &&
    !singleElement.properties.layoutProperties.fieldImageFile
  ) {
    pageErrors.push([
      'form_error.Element (',
      `${pageElementPosition}`,
      'form_error.) has missing image',
    ]);
  }

  return pageErrors;
};

export const uploadImageFieldsMedia = async (formElementsList, formDetails) => {
  const imageElements = formElementsList.filter((element) => element.elementType === 'image');

  const formId = formDetails?._id ?? '';

  const formVersionId = (formDetails?.versions ?? []).length
    ? sortBy(formDetails.versions, (e) => parseInt(e.version))[formDetails.versions.length - 1]._id
    : '';

  for (const imageElement of imageElements) {
    let imageProps = imageElement.properties;
    const fieldImageFile = imageProps.layoutProperties.fieldImageFile ?? null;
    const fieldImage = imageProps.layoutProperties.fieldImage ?? null;

    if (fieldImageFile) {
      if (fieldImage) await deleteFieldImageIfExist(fieldImage);
      const uploadResponse = await uploadSingleFieldImage(fieldImageFile, formId, formVersionId);
      delete imageProps.layoutProperties.fieldImageFile;

      imageProps.layoutProperties = {
        ...imageProps.layoutProperties,
        ...(uploadResponse ? { fieldImage: uploadResponse } : undefined),
      };
      imageElement.properties = imageProps;
    }
  }
};

const deleteFieldImageIfExist = async (fieldImage) => {
  if (fieldImage.id) {
    await CallAPI(
      'DELETE_MEDIA',
      {
        folderType: 'forms',
        mediaType: 'image',
        mediaId: fieldImage.id,
        imageName: fieldImage.imageName,
        formId: fieldImage.formId,
        versionId: fieldImage.versionId,
      },
      null,
      null,
      null,
      null,
    );
  }
};
const uploadSingleFieldImage = async (file, formId, formVersionId) => {
  const imageData = { imageName: `${Date.now()}.jpg`, formId, versionId: formVersionId };
  const response = await CallAPI(
    'GET_FORM_MEDIA_UPLOAD_URL',
    { ...imageData, mediaType: 'image' },
    null,
    null,
  );
  if (response.status) {
    await uploadImage(file, response.data, null);
    return { ...imageData, id: response.data.id };
  }
  return null;
};

export const createStructureForExportJson = (elements) => {
  elements.map((singleElement) => {
    delete singleElement.company;
    delete singleElement.elementGlobalId;
    delete singleElement._id;
    delete singleElement.uniqueIdToReplace;
    if (singleElement.elementType === 'dataSource') {
      singleElement.properties.basicProperties = {
        ...singleElement.properties.basicProperties,
        dataSource: '',
        displayColumn: '',
        valueColumn: '',
        bindingList: [],
      };
    } else if (singleElement.elementType === 'externalApi') {
      singleElement.properties.basicProperties = {
        ...singleElement.properties.basicProperties,
        apiMethod: 'post',
        apiParamType: 'url',
        apiParamValue: '',
        statusFieldBinding: '',
        apiUrl: '',
        bindingList: [],
        auth: {
          authType: 'api_auth',
          authValue: '',
          authApiUrl: '',
          authApiMethod: 'post',
          responseAuthKeyName: '',
          parameters: { client_name: '', client_id: '', client_secret: '' },
        },
      };
    } else if (singleElement.elementType === 'image') {
      singleElement.properties.layoutProperties = {
        ...singleElement.properties.layoutProperties,
        fieldImageFile: null,
        fieldImage: null,
      };
    }
  });
  return elements;
};
