import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { v4 as uuidV4 } from 'uuid';
import layersIcon from 'assets/images/icons/layers.svg';
import dotPointsIcon from 'assets/images/icons/dot-points.svg';
import plusIcon from 'assets/images/icons/plus.svg';
import ConfirmationModal from 'components/common-components/ConfirmationModal';
import DropZone from './MidSectionComponents/DropZone';
import PageElement from './MidSectionComponents/PageElement';
import SupervisorFieldComponent from './MidSectionComponents/SupervisorFieldComponent';
import SectionComponent from './MidSectionComponents/SectionComponent';
import UserFieldComponent from './MidSectionComponents/UserFieldComponent';
import { fieldCategoryList, formElementsDefaultProperties } from './utils';
import { updateSavedChanges } from 'config/config';
import {
  removeFieldWithChildElements,
  removePageElementWithChildElements,
  removeSectionWithChildElements,
  swapFieldAndSectionElements,
  swapPageElements,
} from './actions';

function FormPreview({
  selectedFieldCategory,
  setSelectedFieldCategory,
  selectedField,
  setSelectedField,
  formProperties,
  setFormProperties,
  draggedElement,
  setDraggedElement,
  direction,
  dataSourcesList,
  propertiesSectionRef,
}) {
  const { t } = useTranslation();
  const [propertiesSectionHeight, setPropertiesSectionHeight] = useState(null);
  const [statusFieldAlreadyAdded, setStatusFieldAlreadyAdded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(Object.keys(formProperties).length - 2);
  const [selectedView, setSelectedView] = useState('list-view');
  useEffect(() => {
    setTotalPages(Object.keys(formProperties).length - 2);
  }, [formProperties]);
  const onComponentDrop = (index) => {
    if (draggedElement && draggedElement.elementType) {
      let properties = cloneDeep(formProperties);
      if (draggedElement.elementType === 'supervisor_status') {
        if (properties.otherDetails.hasStatusFieldInForm) {
          setStatusFieldAlreadyAdded(true);
          return;
        } else {
          properties.otherDetails.hasStatusFieldInForm = true;
        }
      }
      updateSavedChanges(true, 'FORM_DESIGN');

      const currentTimeStamp = Date.now();
      if (draggedElement.elementType !== 'page') {
        let elementToAdd = {
          elementType: draggedElement.elementType,
          properties: cloneDeep(formElementsDefaultProperties[draggedElement.elementType]),
          isOpen: false,
        };
        if (draggedElement.elementType !== 'section')
          elementToAdd = {
            ...elementToAdd,
            uniqueIdToReplace: `field_${currentTimeStamp}`,
          };
        if (elementToAdd.properties.otherProperties)
          elementToAdd.properties.otherProperties.fieldUniqueId = `field_${currentTimeStamp}`;
        let elementAddedIn =
          draggedElement.fieldCategory === 'user-field' ? 'page' + currentPage : 'supervisorFields';
        if (index === -1) {
          properties[elementAddedIn].elements.push(elementToAdd);
        } else {
          let elementsList = properties[elementAddedIn].elements;

          // skip until there is child element
          let surpassIndex = index;
          for (; surpassIndex < elementsList.length; surpassIndex++) {
            if (!elementsList[surpassIndex + 1] || !elementsList[surpassIndex + 1].childReferenceId)
              break;
          }

          elementsList.splice(surpassIndex + 1, 0, elementToAdd);
        }

        if (
          !['page', 'section', 'image', 'dataSource', 'externalApi'].includes(
            draggedElement.elementType,
          )
        ) {
          properties.otherDetails.allFormElementsObj = {
            ...properties.otherDetails.allFormElementsObj,
            [elementToAdd.uniqueIdToReplace]: {
              fieldName: 'UnTitled',
              fieldId: elementToAdd.uniqueIdToReplace,
            },
          };
        }
      } else {
        let pageNumber = currentPage + 1;
        let elementToAdd = {
          elementType: 'page',
          properties: cloneDeep(formElementsDefaultProperties.page),
          isOpen: false,
        };
        elementToAdd.properties.otherProperties.fieldUniqueId = `field_${currentTimeStamp}`;

        for (let index = totalPages; index > currentPage; index--) {
          let nextPage = index + 1;
          properties['page' + nextPage] = { elements: properties['page' + index].elements };
        }

        properties['page' + pageNumber] = {
          elements: [elementToAdd],
        };

        const orderedFormProps = Object.keys(cloneDeep(properties))
          .sort()
          .reduce((obj, key) => {
            obj[key] = properties[key];
            return obj;
          }, {});
        properties = cloneDeep(orderedFormProps);
      }
      setFormProperties(properties);
      if (draggedElement.elementType === 'page') {
        setCurrentPage((prev) => prev + 1);
        setSelectedField(null);
      } else {
        if (
          selectedField &&
          selectedField.position > index &&
          index !== -1 &&
          selectedField.type === draggedElement.fieldCategory
        ) {
          setSelectedField((prev) => {
            return { ...prev, position: prev.position + 1 };
          });
        }
      }
      setDraggedElement(null);
    }
  };

  const addPage = () => {
    let properties = cloneDeep(formProperties);

    updateSavedChanges(true, 'FORM_DESIGN');

    const currentTimeStamp = Date.now();

    let pageNumber = totalPages + 1;
    let elementToAdd = {
      elementType: 'page',
      properties: cloneDeep(formElementsDefaultProperties.page),
      isOpen: false,
    };
    elementToAdd.properties.otherProperties.fieldUniqueId = `field_${currentTimeStamp}`;

    properties['page' + pageNumber] = { elements: [elementToAdd] };

    const orderedFormProps = Object.keys(cloneDeep(properties))
      .sort()
      .reduce((obj, key) => {
        obj[key] = properties[key];
        return obj;
      }, {});
    properties = cloneDeep(orderedFormProps);

    setFormProperties(properties);
    setCurrentPage(pageNumber);
    setSelectedField(
      cloneDeep({
        type: 'user-field',
        position: 0,
        currentPage: pageNumber,
        elementType: elementToAdd.elementType,
      }),
    );
  };

  const addTriggerInElement = (elementIndex) => {
    const defaultTrigger = { matcher: 'is', matchingValue: '', types: [] };
    // add default structure of trigger
    let properties = cloneDeep(formProperties);
    updateSavedChanges(true, 'FORM_DESIGN');

    let elementToAddTrigger = null;
    elementToAddTrigger = properties['page' + currentPage].elements[elementIndex];

    if (elementToAddTrigger) {
      if (!elementToAddTrigger.triggers) elementToAddTrigger.triggers = [{ ...defaultTrigger }];
      else elementToAddTrigger.triggers.push({ ...defaultTrigger });
    }

    console.log(properties['page' + currentPage].elements[elementIndex]);
    setFormProperties(properties);
  };

  const handleTriggerElementChange = (elementIndex, triggerIndex, name, value) => {
    // for matcher and matchingValue
    let properties = cloneDeep(formProperties);
    updateSavedChanges(true, 'FORM_DESIGN');

    properties['page' + currentPage].elements[elementIndex].triggers[triggerIndex][name] = value;

    console.log(properties['page' + currentPage].elements[elementIndex]);
    setFormProperties(properties);
  };

  const findInNextDepth = (properties, elementIndex) => {
    let newSurpassIndex = elementIndex;
    let lastDepthLastChildTriggerElement = null;
    const lastDepthTriggers = properties['page' + currentPage].elements[elementIndex].triggers;
    if (lastDepthTriggers?.length) {
      for (let index = lastDepthTriggers.length - 1; index >= 0; index--) {
        lastDepthLastChildTriggerElement = properties['page' + currentPage].elements[
          elementIndex
        ].triggers[index].types
          .filter((t) => t.type === 'ask_a_question')
          .at(-1);
        if (lastDepthLastChildTriggerElement) break;
      }
    }
    if (lastDepthLastChildTriggerElement) {
      newSurpassIndex = properties['page' + currentPage].elements.findIndex(
        (e) => e.childReferenceId === lastDepthLastChildTriggerElement.childReferenceId,
      );
    }
    return newSurpassIndex;
  };

  const findSurpassIndex = (properties, elementIndex, triggerIndex) => {
    let surpassIndex = 0;

    const lastChildElement = properties['page' + currentPage].elements[elementIndex].triggers[
      triggerIndex
    ].types
      .filter((t) => t.type === 'ask_a_question')
      .at(-1);

    if (triggerIndex === 0) {
      if (lastChildElement) {
        surpassIndex = properties['page' + currentPage].elements.findIndex(
          (e) => e.childReferenceId === lastChildElement.childReferenceId,
        );

        if (surpassIndex > -1) {
          //search next level children
          surpassIndex = findInNextDepth(properties, surpassIndex);
        }
      }
    } else {
      console.log({ lastChildElement });

      if (lastChildElement) {
        surpassIndex = properties['page' + currentPage].elements.findIndex(
          (e) => e.childReferenceId === lastChildElement.childReferenceId,
        );
      } else {
        let previousTriggerLastChild = null;
        for (let index = triggerIndex - 1; index >= 0; index--) {
          previousTriggerLastChild = properties['page' + currentPage].elements[
            elementIndex
          ].triggers[index].types
            .filter((t) => t.type === 'ask_a_question')
            .at(-1);
          if (previousTriggerLastChild) break;
        }
        if (previousTriggerLastChild) {
          surpassIndex = properties['page' + currentPage].elements.findIndex(
            (e) => e.childReferenceId === previousTriggerLastChild.childReferenceId,
          );

          if (surpassIndex > -1) {
            //search next level children
            surpassIndex = findInNextDepth(properties, surpassIndex);
          }
        }
      }
    }

    return surpassIndex;
  };

  const addTriggerType = (elementIndex, triggerIndex, triggerType, elementType) => {
    console.log(elementIndex, triggerIndex, triggerType, elementType);

    let properties = cloneDeep(formProperties);
    updateSavedChanges(true, 'FORM_DESIGN');

    let elementToAdd = undefined;

    if (triggerType === 'ask_a_question') {
      const newId = uuidV4();
      const currentTimeStamp = Date.now();
      elementToAdd = {
        elementType,
        properties: cloneDeep(formElementsDefaultProperties[elementType]),
        isOpen: false,
        uniqueIdToReplace: `field_${currentTimeStamp}`,
        triggerFlowReferenceId: newId,
        childReferenceId: newId,
      };

      if (elementToAdd.properties.otherProperties)
        elementToAdd.properties.otherProperties.fieldUniqueId = `field_${currentTimeStamp}`;

      let surpassIndex = findSurpassIndex(properties, elementIndex, triggerIndex);
      console.log({ surpassIndex });

      console.log({ surpassIndex, elementIndex });

      //if surpass index > 0 then their are child elements needs to skip
      if (surpassIndex > 0)
        properties['page' + currentPage].elements.splice(surpassIndex + 1, 0, elementToAdd);
      else properties['page' + currentPage].elements.splice(elementIndex + 1, 0, elementToAdd);
    }

    const triggerToPush =
      triggerType === 'ask_a_question'
        ? {
            type: triggerType,
            triggerFlowReferenceId: elementToAdd.triggerFlowReferenceId, // for backend mapping
            childReferenceId: elementToAdd.childReferenceId, // for frontend mapping
            elementGlobalId: null,
          }
        : { type: triggerType };

    properties['page' + currentPage].elements[elementIndex].triggers[triggerIndex].types.push(
      cloneDeep(triggerToPush),
    );

    console.log(properties['page' + currentPage].elements[elementIndex]);
    setFormProperties(properties);
  };

  const removeSingleTriggerType = (properties, elementIndex, triggerIndex, typeIndex) => {
    const childElementIndicesToDelete = [];
    const childElement =
      properties['page' + currentPage].elements[elementIndex].triggers[triggerIndex].types[
        typeIndex
      ];
    if (childElement.type === 'ask_a_question') {
      const childElementIndex = properties['page' + currentPage].elements.findIndex(
        (e) => e.childReferenceId === childElement.childReferenceId,
      );
      console.log({ childElementIndex });

      // also delete the child element from main list
      if (childElementIndex > -1) {
        childElementIndicesToDelete.push(childElementIndex);

        //Now finding if that child element has trigger elements, we need to delete those as well
        const childElementTriggers =
          properties['page' + currentPage].elements[childElementIndex].triggers;
        if (childElementTriggers?.length) {
          for (const trigger of childElementTriggers) {
            for (let i = 0; i < trigger.types.length; i++) {
              if (trigger.types[i].type === 'ask_a_question') {
                const nextChildIndex = properties['page' + currentPage].elements.findIndex(
                  (e) => e.childReferenceId === trigger.types[i].childReferenceId,
                );
                if (nextChildIndex > -1) childElementIndicesToDelete.push(nextChildIndex);
              }
            }
          }
        }
      }
    }
    console.log({ typeIndex });

    console.log(childElementIndicesToDelete);
    // Reverse sort for removing elements from downwards
    childElementIndicesToDelete.sort((a, b) => b - a);
    childElementIndicesToDelete.forEach((indexToDelete) => {
      properties['page' + currentPage].elements.splice(indexToDelete, 1);
    });
    properties['page' + currentPage].elements[elementIndex].triggers[triggerIndex].types.splice(
      typeIndex,
      1,
    );
  };

  const removeTriggerType = (elementIndex, triggerIndex, typeIndex) => {
    let properties = cloneDeep(formProperties);
    updateSavedChanges(true, 'FORM_DESIGN');

    removeSingleTriggerType(properties, elementIndex, triggerIndex, typeIndex);

    console.log(properties['page' + currentPage].elements[elementIndex]);
    setFormProperties(properties);
  };

  const removeAllTriggers = (elementIndex) => {
    let properties = cloneDeep(formProperties);
    updateSavedChanges(true, 'FORM_DESIGN');

    const elementTriggers = properties['page' + currentPage].elements[elementIndex].triggers;

    if (elementTriggers?.length) {
      for (let triggerIndex = elementTriggers.length - 1; triggerIndex >= 0; triggerIndex--) {
        const triggerTypes = elementTriggers[triggerIndex].types;
        for (let typeIndex = triggerTypes.length - 1; typeIndex >= 0; typeIndex--) {
          removeSingleTriggerType(properties, elementIndex, triggerIndex, typeIndex);
        }
      }
      if (elementTriggers.every((t) => t.types.length === 0))
        properties['page' + currentPage].elements[elementIndex].triggers = null;
    }

    console.log(properties['page' + currentPage].elements[elementIndex]);
    setFormProperties(properties);
  };

  const toggleUserElement = (index) => {
    let properties = cloneDeep(formProperties);

    const isOpen = selectedField?.position === index ? false : true;
    properties['page' + currentPage].elements[index].isOpen = isOpen;
    setFormProperties(properties);
  };
  const toggleSupervisorElement = (index) => {
    let properties = cloneDeep(formProperties);

    properties['supervisorFields'].elements[index].isOpen =
      !properties['supervisorFields'].elements[index].isOpen;
    setFormProperties(properties);
  };

  const toggleAllElements = (isOpen) => {
    let properties = cloneDeep(formProperties);

    Array.from(Array(totalPages).keys()).map((_, i) => {
      for (let index = 0; index < properties['page' + (i + 1)].elements.length; index++) {
        properties['page' + currentPage].elements[index].isOpen = isOpen;
      }
    });
    for (let index = 0; index < properties['supervisorFields'].elements.length; index++) {
      properties['supervisorFields'].elements[index].isOpen = isOpen;
    }
    setFormProperties(properties);
  };

  useEffect(() => {
    if (!propertiesSectionRef.current) return;
    const resizeObserver = new ResizeObserver((entries) => {
      // Do what you want to do when the size of the element changes
      if (entries.length) {
        const contentReact = entries[0].contentRect;
        setPropertiesSectionHeight(contentReact.height);
      }
    });
    resizeObserver.observe(propertiesSectionRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  const updateUniqueIdValues = (elementsList) => {
    const currentTimeStamp = Date.now();
    elementsList.map((singleElement, index) => {
      if (
        singleElement.properties.otherProperties &&
        singleElement.properties.otherProperties.fieldUniqueId
      )
        singleElement.properties.otherProperties.fieldUniqueId = `field_${
          currentTimeStamp + index
        }`;
      // singleElement.properties.otherProperties.fieldUniqueId =
      //   'duplicate_' +
      //   (currentPage + 1) +
      //   '_' +
      //   singleElement.properties.otherProperties.fieldUniqueId;
      if (singleElement.elementType === 'dataSource')
        singleElement.properties.basicProperties.bindingList = [];
      delete singleElement.elementGlobalId;
      delete singleElement._id;
      delete singleElement.triggers; // also remove triggers for new element
    });
    return elementsList;
  };
  const unBindRemovedElements = (elementsList, formProps) => {
    let allRemovedElementsGlobalIds = elementsList.map((singleElement) => {
      return singleElement.elementGlobalId
        ? singleElement.elementGlobalId
        : singleElement.uniqueIdToReplace;
    });
    Object.keys(formProps).map((singlePageElement) => {
      if (singlePageElement.indexOf('page') === 0) {
        formProps[singlePageElement].elements.map((singlePageElement) => {
          if (singlePageElement.elementType === 'dataSource') {
            let updatedBindingList =
              singlePageElement.properties.basicProperties.bindingList.filter(
                (singleBindElement) => {
                  if (
                    !(
                      singleBindElement &&
                      allRemovedElementsGlobalIds.indexOf(singleBindElement.fieldId) > -1
                    )
                  ) {
                    return singleBindElement;
                  }
                },
              );
            singlePageElement.properties.basicProperties.bindingList = updatedBindingList;
          }
        });
      }
    });
    return formProps;
  };

  const changeFormElementOrder = (type, detail, action) => {
    updateSavedChanges(true, 'FORM_DESIGN');
    setSelectedField(null);
    let removedElements = [];
    let deletedFieldType = '';
    let properties = cloneDeep(formProperties);
    let elementAddedIn = type === 'supervisor_field' ? 'supervisorFields' : 'page' + currentPage;
    let currentPageElements = properties[elementAddedIn].elements;
    let isNeededToReplaceElements = true;
    const deletedFieldIds = [];

    /* New Element actions implementation */
    if (type === 'field' || type === 'supervisor_field') {
      if (action === 'increase' || action === 'decrease') {
        swapFieldAndSectionElements(currentPageElements, detail.position, action);
      } else if (action === 'delete') {
        const deletedFieldId = removeFieldWithChildElements(currentPageElements, detail.position);
        if (deletedFieldId) deletedFieldIds.push(deletedFieldId);
      }
    } else if (type === 'section') {
      if (action === 'increase' || action === 'decrease') {
        swapFieldAndSectionElements(currentPageElements, detail.position, action);
      } else if (action === 'delete') {
        removeSectionWithChildElements(currentPageElements, detail.position, removedElements);
      }
    } else if (type === 'page') {
      if (action === 'increase' || action === 'decrease') {
        isNeededToReplaceElements = false;
        swapPageElements(properties, detail, action);
      } else if (action === 'delete') {
        isNeededToReplaceElements = false;
        removePageElementWithChildElements(
          properties,
          detail,
          currentPage,
          setCurrentPage,
          setTotalPages,
          removedElements,
        );
      }
    }
    /* ********************* */

    if (action === 'increase' || action === 'decrease') {
      // let replacedIndex = action === 'increase' ? detail.position + 1 : detail.position - 1;
      if (type === 'field' || type === 'section' || type === 'supervisor_field') {
        // [currentPageElements[detail.position], currentPageElements[replacedIndex]] = [
        //   currentPageElements[replacedIndex],
        //   currentPageElements[detail.position],
        // ];
      } else {
        // isNeededToReplaceElements = false;
        // const replacingPageElements = properties['page' + detail.pageNumber].elements;
        // properties['page' + detail.pageNumber].elements =
        //   properties['page' + replacedIndex].elements;
        // properties['page' + replacedIndex].elements = replacingPageElements;
      }
    }
    if (type === 'field' || type === 'supervisor_field') {
      if (action === 'delete') {
        // deletedFieldType = currentPageElements[detail.position].elementType;
        // removedElements.push(currentPageElements[detail.position]);
        // currentPageElements.splice(detail.position, 1);
      } else if (action === 'duplicate') {
        let updatedElements = updateUniqueIdValues([
          cloneDeep(currentPageElements[detail.position]),
        ]);
        currentPageElements.splice(detail.position + 1, 0, updatedElements[0]);
      }
    } else if (action === 'delete') {
      if (type === 'page') {
        // isNeededToReplaceElements = false;
        // removedElements = properties['page' + detail.pageNumber].elements;
        // if (detail.position === detail.totalPages) {
        //   delete properties['page' + detail.pageNumber];
        //   // setCurrentPage(currentPage - 1);
        // } else {
        //   for (let index = detail.position; index < detail.totalPages; index++) {
        //     let nextPage = index + 1;
        //     properties['page' + index].elements = properties['page' + nextPage].elements;
        //   }
        //   delete properties['page' + detail.totalPages];
        // }
        // setTotalPages(totalPages - 1);
      } else if (type === 'section') {
        // let deletionIndex = -1;
        // let deletionCount = 0;
        // for (let index = detail.position; index < currentPageElements.length; index++) {
        //   if (index !== detail.position && currentPageElements[index].elementType === 'section') {
        //     break;
        //   }
        //   if (deletionIndex === -1) deletionIndex = index;
        //   deletionCount++;
        //   removedElements.push(currentPageElements[index]);
        // }
        // if (deletionIndex > -1) {
        //   currentPageElements.splice(deletionIndex, deletionCount);
        // }
      }
    } else if (action === 'duplicate') {
      let elementsListToDuplicate = [];
      let elementsToSkip = 0;
      if (type === 'page') {
        for (let index = 0; index < properties['page' + detail.position].elements.length; index++) {
          const element = properties['page' + detail.position].elements[index];
          if (!element.childReferenceId) elementsListToDuplicate.push(cloneDeep(element));
        }
      } else if (type === 'section') {
        for (let index = detail.position; index < currentPageElements.length; index++) {
          if (index !== detail.position && currentPageElements[index].elementType === 'section') {
            break;
          }
          elementsToSkip++;
          if (!currentPageElements[index].childReferenceId)
            elementsListToDuplicate.push(cloneDeep(currentPageElements[index]));
        }
      }
      let updatedElements = updateUniqueIdValues(elementsListToDuplicate);
      if (type === 'page') {
        isNeededToReplaceElements = false;
        if (detail.position === detail.totalPages) {
          properties['page' + (detail.totalPages + 1)] = {
            elements: updatedElements,
          };
        } else {
          for (let index = detail.totalPages; index > detail.position; index--) {
            let nextPage = index + 1;
            properties['page' + nextPage] = { elements: properties['page' + index].elements };
          }
          properties['page' + (detail.position + 1)] = { elements: updatedElements };
        }
      } else if (type === 'section') {
        currentPageElements.splice(detail.position + elementsToSkip, 0, ...updatedElements);
      }
    }

    if (deletedFieldIds.length) {
      deletedFieldIds.forEach((deletedFieldId) => {
        if (deletedFieldId) delete properties.otherDetails.allFormElementsObj[deletedFieldId];
      });
    }

    // when any changes are done in current page
    if (isNeededToReplaceElements) properties[elementAddedIn].elements = currentPageElements;
    setFormProperties(properties);
    if (removedElements.length > 0) {
      let updatedFormProperties = unBindRemovedElements(removedElements, properties);
      setFormProperties(updatedFormProperties);
    }
    if (deletedFieldType === 'supervisor_status') {
      properties.otherDetails.hasStatusFieldInForm = false;
      setFormProperties(properties);
    }
  };
  const pageUpdated = (pageNumber) => {
    setCurrentPage(pageNumber);
    setSelectedField(null);
  };

  const checkSection = (pageElements, currentIndex) => {
    let isInSection = false;
    let isSectionOpen = true;
    if (pageElements[currentIndex].elementType === 'section')
      return { isInSection: false, isSectionOpen: true };

    for (let index = currentIndex - 1; index > 0; index--) {
      if (pageElements[index].elementType === 'section') {
        isInSection = true;
        isSectionOpen = pageElements[index].isOpen;
        break;
      }
    }
    return { isInSection, isSectionOpen };
  };

  const selectComponent = (position, elementType) => {
    if (selectedView === 'list-view') toggleUserElement(position);
    if (selectedField && selectedField.type === 'user-field' && selectedField.position === position)
      setSelectedField(null);
    else setSelectedField(cloneDeep({ type: 'user-field', position, currentPage, elementType }));
  };

  return (
    <>
      <div className='form-preview'>
        <div className='d-flex'>
          {fieldCategoryList.map((fieldCategory, index) => (
            <div
              key={index}
              className={`field-category-header${
                selectedFieldCategory === fieldCategory.name ? ' field-category-selected' : ''
              }`}
              onClick={() => {
                setSelectedField(null);
                setSelectedFieldCategory(fieldCategory.name);
                toggleAllElements(false);
              }}
            >
              {t(fieldCategory.text)}
            </div>
          ))}
        </div>
        <div>
          {selectedFieldCategory === 'user-field' ? (
            <div className='page-list'>
              {Array.from(Array(totalPages).keys()).map((index) => {
                const page = formProperties['page' + (index + 1)].elements.find(
                  (e) => e.elementType === 'page',
                );
                return (
                  <PageElement
                    key={index}
                    page={page}
                    currentPage={currentPage}
                    pageNumber={index + 1}
                    totalPages={totalPages}
                    onClick={() => {
                      pageUpdated(index + 1);
                      setSelectedField(
                        cloneDeep({
                          type: 'user-field',
                          position: 0,
                          currentPage: index + 1,
                          elementType: 'page',
                        }),
                      );
                    }}
                    changeFormElementOrder={changeFormElementOrder}
                  />
                );
              })}
              <div>
                <img src={plusIcon} />
                <label className='theme-text-green-1' onClick={addPage}>
                  Add Page
                </label>
              </div>
            </div>
          ) : (
            <div></div>
          )}
          <div className='view-options'>
            <div
              className={selectedView === 'full-view' ? 'selected' : ''}
              onClick={() => {
                setSelectedView('full-view');
                toggleAllElements(true);
                setSelectedField(null);
              }}
            >
              <img src={layersIcon} />
              <label>Full View</label>
            </div>
            <div
              className={selectedView === 'list-view' ? 'selected' : ''}
              onClick={() => {
                setSelectedView('list-view');
                toggleAllElements(false);
                setSelectedField(null);
              }}
            >
              <img src={dotPointsIcon} />
              <label>List View</label>
            </div>
          </div>
        </div>
        <div className='text-center py-2'>
          <label className='theme-size-0_8'>{t('form_rearrange_elements')}</label>
        </div>
      </div>
      <div
        className='fields-playground'
        style={{
          direction: direction,
          ...(propertiesSectionHeight ? { height: `${propertiesSectionHeight - 121}px` } : {}),
        }}
      >
        <div
          className={`d-flex flex-column page-elements${
            selectedFieldCategory !== 'user-field' ? ' d-none' : ''
          }`}
        >
          {formProperties &&
            formProperties['page' + currentPage] &&
            formProperties['page' + currentPage].elements &&
            formProperties['page' + currentPage].elements.map((element, index) => {
              const { isInSection, isSectionOpen } = checkSection(
                formProperties['page' + currentPage].elements,
                index,
              );
              return !isSectionOpen || element.childReferenceId ? (
                <Fragment key={index}></Fragment>
              ) : (
                <div
                  key={index}
                  className={isInSection ? ' mx-3 ps-3 border-left-green-1-h-2' : ''}
                >
                  {/* {element.elementType !== 'page' && index > 1 && (
                    <DropZone onComponentDrop={() => onComponentDrop(index - 1)} shrink />
                  )} */}
                  {element.elementType === 'page' ? (
                    <></>
                  ) : element.elementType === 'section' ? (
                    <SectionComponent
                      elementIndex={index}
                      section={element}
                      direction={direction}
                      selectComponent={() => {
                        if (selectedView === 'list-view') toggleUserElement(index);
                        if (
                          selectedField &&
                          selectedField.type === 'user-field' &&
                          selectedField.position === index
                        )
                          setSelectedField(null);
                        else
                          setSelectedField(
                            cloneDeep({
                              type: 'user-field',
                              position: index,
                              currentPage,
                              elementType: element.elementType,
                            }),
                          );
                      }}
                      changeFormElementOrder={changeFormElementOrder}
                      isSelected={selectedView === 'full-view' || element.isOpen}
                    />
                  ) : (
                    <UserFieldComponent
                      elementIndex={index}
                      field={element}
                      direction={direction}
                      depth={1}
                      selectComponent={selectComponent}
                      changeFormElementOrder={changeFormElementOrder}
                      isSelected={selectedView === 'full-view' || element.isOpen}
                      dataSourcesList={dataSourcesList}
                      currentPage={currentPage}
                      pageElements={formProperties['page' + currentPage].elements}
                      addTriggerInElement={addTriggerInElement}
                      handleTriggerElementChange={handleTriggerElementChange}
                      addTriggerType={addTriggerType}
                      removeTriggerType={removeTriggerType}
                      removeAllTriggers={removeAllTriggers}
                    />
                  )}
                  {element.elementType !== 'page' &&
                    index < formProperties['page' + currentPage].elements.length - 1 && (
                      <DropZone onComponentDrop={() => onComponentDrop(index)} shrink />
                    )}
                </div>
              );
            })}
        </div>
        <div
          className={`d-flex flex-column supervisor-elements${
            selectedFieldCategory !== 'supervisor-field' ? ' d-none' : ''
          }`}
        >
          {formProperties &&
            formProperties.supervisorFields.elements.map((element, index) => {
              return (
                <div key={index}>
                  <SupervisorFieldComponent
                    elementIndex={index}
                    field={element}
                    selectComponent={() => {
                      if (selectedView === 'list-view') toggleSupervisorElement(index);
                      if (
                        selectedField &&
                        selectedField.type === 'supervisor-field' &&
                        selectedField.position === index
                      )
                        setSelectedField(null);
                      else
                        setSelectedField(
                          cloneDeep({
                            type: 'supervisor-field',
                            position: index,
                            currentPage: 0,
                            elementType: element.elementType,
                          }),
                        );
                    }}
                    changeFormElementOrder={changeFormElementOrder}
                    isSelected={selectedView === 'full-view' || element.isOpen}
                  />
                  <DropZone onComponentDrop={() => onComponentDrop(index)} shrink />
                </div>
              );
            })}
        </div>
        <DropZone onComponentDrop={() => onComponentDrop(-1)} />
        {statusFieldAlreadyAdded && (
          <ConfirmationModal
            show={statusFieldAlreadyAdded}
            title={t('alert_form_element_status_already_added')}
            message={t('alert_form_element_status_already_added_detail')}
            actionList={[
              {
                color: 'black-1',
                text: t('button_ok'),
                onClick: () => setStatusFieldAlreadyAdded(false),
              },
            ]}
          />
        )}
      </div>
    </>
  );
}
FormPreview.propTypes = {
  selectedFieldCategory: PropTypes.string.isRequired,
  setSelectedFieldCategory: PropTypes.func.isRequired,
  draggedElement: PropTypes.object,
  setDraggedElement: PropTypes.func,
  selectedField: PropTypes.object,
  direction: PropTypes.string.isRequired,
  formProperties: PropTypes.object.isRequired,
  setFormProperties: PropTypes.func.isRequired,
  setSelectedField: PropTypes.func.isRequired,
  dataSourcesList: PropTypes.array.isRequired,
  propertiesSectionRef: PropTypes.object.isRequired,
};
export default FormPreview;
