import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TextField from 'components/form-components/TextField';
import SingleCheckBoxField from 'components/form-components/SingleCheckBoxField';
import submissionAttachment from 'assets/images/icons/submission-attachment.svg';
import { OverlayTrigger, Popover } from 'react-bootstrap';

const TableHeader = ({
  filterEnabled,
  mediaEnabled,
  tableFields,
  onSelection,
  isActionsEnabled,
  isSelectionEnabled,
  selectionValue,
  debounceFunc,
  filteringData,
  setFilteringData,
  // setHeaderWidthMap,
  headerSectionTitles,
  anySectionExists,
  initialColsWidthMap,
  widthOfCheckboxCol,
  applyTablePartitionSideBorder,
  applySecSideBorder,
  htmlDirection
}) => {
  const [hoverTitleShowMap, setHoverTitleShowMap] = useState({});
  const [initialColsWidthMapModified, setInitialColsWidthMapModified] = useState({});

  useEffect(()=>{
    // Convert to an object with numeric string keys
    const newObj = Object.values(initialColsWidthMap).reduce((acc, value, index) => {
      acc[index] = value;
      return acc;
    }, {});
    setInitialColsWidthMapModified(newObj);    
  }, [initialColsWidthMap]);


  const toggleShowMap = (name) =>
    setHoverTitleShowMap({ ...hoverTitleShowMap, [name]: !(hoverTitleShowMap[name] ?? false) });

  const popover = ({ fullFieldTitle }) => (
    <Popover className='min-width-176' id='popover-basic'>
      <Popover.Body>
        <label>{fullFieldTitle}</label>
      </Popover.Body>
    </Popover>
  );

  return (
    <thead className={`${headerSectionTitles?.length>0 && anySectionExists(headerSectionTitles) ? 'adjust-tableheader' : ''}`}>
      {filterEnabled && (
        <tr>
          {isSelectionEnabled && <th className='filter-field'></th>}
          {tableFields.map((singleHeaderField, index) => {
            return singleHeaderField.isSelected ? (
              <th key={index} className='filter-field'>
                {singleHeaderField.isFilterAble ? (
                  <TextField
                    value={
                      filteringData[singleHeaderField.name]
                        ? filteringData[singleHeaderField.name]
                        : ''
                    }
                    name={singleHeaderField.name}
                    debounceFunc={debounceFunc}
                    handleChange={setFilteringData}
                    placeholder=''
                    shrink
                    classes='filter-field'
                  />
                ) : (
                  ''
                )}
              </th>
            ) : null;
          })}
          {isActionsEnabled && <th className='filter-field'></th>}
        </tr>
      )}
      <tr className='header-row'>
        <th 
          className='table-checkbox sticky-cell'
          style={{
            width: `${widthOfCheckboxCol}px`,
            ...({
              [htmlDirection ? 'right' : 'left']: '0px'
            })
          }}
          >
          <SingleCheckBoxField
            name={'all'}
            value={selectionValue}
            handleChange={onSelection}
            text={<></>}
            classes=''
          />
        </th>
        
        {tableFields.map((singleHeaderField, index) => {
          return singleHeaderField.isSelected ? (
            <th 
              key={index}
              className = {`nowrap ${initialColsWidthMap[singleHeaderField?.displayName] ? "sticky-cell" : "middle-columns non-sticky-cell"} ${singleHeaderField?.sectionTitle ? 'cell-border-top' : ''} ${applySecSideBorder(singleHeaderField, index)} ${applyTablePartitionSideBorder(singleHeaderField, index)}`}
              style={{
                width: initialColsWidthMap[singleHeaderField.displayName]?.width ? 
                  `${initialColsWidthMap[singleHeaderField.displayName]?.width}px` : "130px",
                ...(initialColsWidthMap[singleHeaderField.displayName]?.left && {
                  [htmlDirection ? 'right' : 'left']: 
                    `${initialColsWidthMap[singleHeaderField.displayName].left}px`
                })
              }}
              >
                {['basicFields', 'supervisorFields'].includes(singleHeaderField.tableType) ? 
                  (
                    <OverlayTrigger
                      trigger={['hover', 'focus']}
                      onToggle={() => toggleShowMap(singleHeaderField.name)}
                      show={hoverTitleShowMap[singleHeaderField.name]}
                      placement='top'
                      rootClose
                      overlay={popover({
                        fullFieldTitle:
                          singleHeaderField.hintText && singleHeaderField.hintText !== ''
                            ? singleHeaderField.hintText
                            : singleHeaderField.displayName,
                      })}
                      >
                      <div>
                        <TableHeaderColumn
                          // setHeaderWidthMap={setHeaderWidthMap}
                          mediaEnabled={mediaEnabled}
                          singleHeaderField={singleHeaderField}
                          htmlDirection={htmlDirection}
                        />
                      </div>
                    </OverlayTrigger>
                  ) : (
                    <TableHeaderColumn
                      // setHeaderWidthMap={setHeaderWidthMap}
                      mediaEnabled={mediaEnabled}
                      singleHeaderField={singleHeaderField}
                    />
                  )
                }
            </th>
          ) : null;
        })}
        {isActionsEnabled && <th></th>}
        {/* add extra column to take remaining width of the column from 100% width of column */}
        <th 
          className='d-flex flex-grow-1'
          >
        </th>
      </tr>

      {
        headerSectionTitles?.length>0 && anySectionExists(headerSectionTitles) ?
        <tr className='section-titles-row'>
          <th 
            className='table-checkbox sticky-cell'
            style={{[htmlDirection ? 'right' : 'left']: "0px", width: "50px"}}
            >
          </th>
          {
            headerSectionTitles.map((data, index) => {
              return(
                <th 
                  key={index}
                  className = {`nowrap text-center ${initialColsWidthMapModified[index]!=undefined ? "sticky-cell" : "middle-columns non-sticky-cell"}`}
                  style={{
                    width: initialColsWidthMapModified[index]?.width ? 
                      `${initialColsWidthMapModified[index]?.width}px` : "130px",
                    ...(initialColsWidthMapModified[index]?.left && {
                      [htmlDirection ? 'right' : 'left']: 
                        `${initialColsWidthMapModified[index].left}px`
                    })
                  }}
                  colSpan={data?.spanLength}
                  >
                    {data?.title}
                  </th>
              )
            })
          }
          <th className='d-flex flex-grow-1'></th>
        </tr>
        : null
      }

    </thead>
  );
};

TableHeader.propTypes = {
  tableFields: PropTypes.array.isRequired,
  onSelection: PropTypes.func,
  filterEnabled: PropTypes.bool.isRequired,
  mediaEnabled: PropTypes.bool,
  isSelectionEnabled: PropTypes.bool,
  isActionsEnabled: PropTypes.bool.isRequired,
  selectionValue: PropTypes.bool.isRequired,
  debounceFunc: PropTypes.func,
  filteringData: PropTypes.object,
  setFilteringData: PropTypes.func,
  // setHeaderWidthMap: PropTypes.func.isRequired,
  headerSectionTitles: PropTypes.array,
  anySectionExists: PropTypes.func,
  initialColsWidthMap: PropTypes.object,
  widthOfCheckboxCol: PropTypes.number,
  applyTablePartitionSideBorder: PropTypes.func,
  applySecSideBorder: PropTypes.func,
  htmlDirection: PropTypes.bool
};

export default TableHeader;

const TableHeaderColumn = ({ singleHeaderField, mediaEnabled, htmlDirection }) => {
  const { t } = useTranslation();

  return (
    <div
      className='d-flex justify-content-between main-div'
      data-field-id={singleHeaderField.name}
    >
      <span>{t(singleHeaderField.displayName)}</span>

      {singleHeaderField?.isMediaAttached && mediaEnabled && (
        <div style={{ position: 'absolute', [htmlDirection ? 'left' : 'right']: '0'}}>
          <img
            className='ps-2 pe-3 theme-background-white-2'
            src={submissionAttachment}
          />
        </div>
      )}
    </div>
  );
};

TableHeaderColumn.propTypes = {
  singleHeaderField: PropTypes.object.isRequired,
  mediaEnabled: PropTypes.bool,
  htmlDirection: PropTypes.bool
  // setHeaderWidthMap: PropTypes.func.isRequired,
};
