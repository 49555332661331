import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import crossGreyIcon from 'assets/images/icons/cross-grey.svg';
import messageQuestionCircleIcon from 'assets/images/icons/message-question-circle.svg';
import eyeOffGreenIcon from 'assets/images/icons/eye-off-green.svg';
import eyeOffGreyIcon from 'assets/images/icons/eye-off-grey.svg';
import fieldCollapseIcon from 'assets/images/icons/field-collapse.svg';
import movieGreyIcon from 'assets/images/icons/movie-grey.svg';
import movieIcon from 'assets/images/icons/movie.svg';
import noteGreyIcon from 'assets/images/icons/note-grey.svg';
import noteIcon from 'assets/images/icons/note.svg';
import photoGreyIcon from 'assets/images/icons/photo-grey.svg';
import photoIcon from 'assets/images/icons/photo.svg';
import plusGreyIcon from 'assets/images/icons/plus-grey-2.svg';
import plusIcon from 'assets/images/icons/plus.svg';
import requiredIcon from 'assets/images/icons/required.svg';
import FieldDetailView from './FieldDetailView';
import NestedSelect from 'components/form-components/NestedSelect';
import SwappingComponent from './SwappingComponent';
import { userFieldList } from '../utils';
import ConfirmationModal from 'components/common-components/ConfirmationModal';

const fieldTypeMap = Object.fromEntries([...userFieldList].map((f) => [f.elementType, f]));

const UserFieldComponent = ({
  field,
  hideSwapping,
  direction,
  depth,
  selectComponent,
  changeFormElementOrder,
  isSelected,
  dataSourcesList,
  currentPage,
  pageElements,
  elementIndex,
  addTriggerInElement,
  handleTriggerElementChange,
  addTriggerType,
  removeTriggerType,
  removeAllTriggers,
}) => {
  const { t } = useTranslation();
  const getDynamicStyle = (fieldName) => {
    const style = {
      color: field.properties?.basicProperties?.[`${fieldName}Color`],
      fontSize: field.properties?.basicProperties?.[`${fieldName}Size`],
    };
    if ((field.properties?.basicProperties?.[`${fieldName}FontType`] ?? []).includes('italic')) {
      style['fontStyle'] = 'italic';
    }
    if ((field.properties?.basicProperties?.[`${fieldName}FontType`] ?? []).includes('bold')) {
      style['fontWeight'] = 'bolder';
    }
    if ((field.properties?.basicProperties?.[`${fieldName}FontType`] ?? []).includes('underline')) {
      style['textDecoration'] = 'underline';
    }
    if (
      (field.properties?.basicProperties?.[`${fieldName}FontType`] ?? []).includes('strikethrough')
    ) {
      style['textDecoration'] = 'line-through';
    }
    if (
      (field.properties?.basicProperties?.[`${fieldName}FontType`] ?? []).includes('underline') &&
      (field.properties?.basicProperties?.[`${fieldName}FontType`] ?? []).includes('strikethrough')
    ) {
      style['textDecoration'] = 'underline line-through';
    }

    return style;
  };

  const isValues = [
    { value: 'is', displayValue: 'is' },
    { value: 'is_not', displayValue: 'is not' },
  ];
  const triggerValues = [
    {
      value: 'ask_a_question',
      displayValue: 'Ask Question',
      disabled: depth > 2,
      nestedOptions: [...userFieldList]
        .filter((f) =>
          [
            'text',
            'number',
            'checkList',
            'image',
            'location',
            'dateTime',
            'signature',
            'ratingSlider',
            'numberSlider',
          ].includes(f.elementType),
        )
        .map((f) => ({
          value: f.elementType,
          displayValue: f.displayName,
        })),
      subOptionsArrowClass: 'invisible',
      subOptionsSelectClass: 'trigger_type',
    },
    { value: 'require_picture', displayValue: 'Require Picture' },
    { value: 'require_video', displayValue: 'Require Video' },
  ];

  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const [triggerDeletionProps, setTriggerDeletionProps] = useState([]);
  const [options, setOptions] = useState(
    (field.properties.basicProperties?.optionList ?? []).map((option) => ({
      value: option.value,
      displayValue: option.responseText,
    })),
  );

  const handleTriggerTypeChange = (name, value, triggerIndex) => {
    console.log(name, value, triggerIndex);
    if (name === 'ask_a_question') {
      addTriggerType(elementIndex, triggerIndex, name, value);
    } else {
      addTriggerType(elementIndex, triggerIndex, value);
    }
  };

  const isOptionsChanged = (fieldOptionList) => {
    const fieldOptionValues = fieldOptionList.map((o) => o.value);
    const fieldOptionText = fieldOptionList.map((o) => o.responseText);
    const currentOptionValues = options.map((o) => o.value);
    const currentOptionText = options.map((o) => o.displayValue);

    if (fieldOptionValues.length !== currentOptionValues.length) return 0;
    else if (!fieldOptionValues.every((v) => currentOptionValues.includes(v))) return 0;
    else if (!fieldOptionText.every((v) => currentOptionText.includes(v))) return 1;
    return 2;
  };

  useEffect(() => {
    if (field.elementType === 'checkList' && field.isOpen) {
      const fieldOptionList = field.properties.basicProperties?.optionList ?? [];

      const isChanged = isOptionsChanged(fieldOptionList);

      if (isChanged < 1) {
        if (options.length > 0) removeAllTriggers(elementIndex); // only when there are already options
      }
      if (isChanged < 2) {
        setOptions(
          fieldOptionList.map((option) => ({
            value: option.value,
            displayValue: option.responseText,
          })),
        );
      }
    }
  }, [field]);

  return (
    <Fragment>
    <div className={`field-component-new${isSelected ? ' selected' : ''}`}>
      <div className='field-component-header'>
        <div className='d-flex gap-2 justify-content-between flex-grow-1'>
          <div
            className='d-flex align-items-center gap-2 flex-grow-1 cursor-pointer'
            onClick={() => selectComponent(elementIndex, field.elementType)}
          >
            <img
              src={fieldCollapseIcon}
              className={`${
                isSelected ? '' : `svg-grey rotate-${direction === 'rtl' ? '90' : '270'}`
              }`}
            />
            <label className='cursor-pointer theme-size-0_9'>
              {field.properties?.basicProperties?.fieldTitle
                ? field.properties?.basicProperties?.fieldTitle
                : t('form_untitled_field')}
            </label>
            {/* <label className='theme-text-black-1 theme-size-0_9'>**Depth: {depth}**</label> */}
          </div>
          <div className='d-flex align-items-center gap-2'>
            {field.properties?.validationProperties?.readOnly && (
              <label className='theme-size-0_8 text-nowrap'>Read Only</label>
            )}
            {field.properties?.validationProperties?.hidden && (
              <img src={isSelected ? eyeOffGreenIcon : eyeOffGreyIcon} />
            )}
            {field.properties?.mediaProperties?.allowVideos && (
              <img src={isSelected ? movieIcon : movieGreyIcon} />
            )}
            {field.properties?.mediaProperties?.allowNotes && (
              <img src={isSelected ? noteIcon : noteGreyIcon} />
            )}
            {field.properties?.mediaProperties?.allowPictures && (
              <img src={isSelected ? photoIcon : photoGreyIcon} />
            )}
            {field.properties?.validationProperties?.required && <img src={requiredIcon} />}
          </div>
        </div>
        <div
          className={`d-flex gap-2 border-${direction === 'ltr' ? 'left' : 'right'}-grey-1-h-1`}
        >
          <div className={`if-icon${field.elementType === 'checkList' ? ' active' : ''}`}>
            <img src={field.elementType === 'checkList' ? plusIcon : plusGreyIcon} />
            <label>IF</label>
          </div>
          <div className='element-icon'>
            <img src={fieldTypeMap[field.elementType].icon} />
          </div>
          {!hideSwapping && (
            <div className='d-flex gap-2'>
              <SwappingComponent
                elementType={field.elementType}
                type='field'
                props={{ position: elementIndex }}
                changeFormElementOrder={changeFormElementOrder}
                isSelected={isSelected}
              />
            </div>
          )}
        </div>
      </div>
      <div className='field-component-body'>
        <p style={getDynamicStyle('fieldHintText')}>
          {field.properties?.basicProperties?.fieldHintText
            ? field.properties?.basicProperties?.fieldHintText
            : t('form_hint')}
        </p>
        <FieldDetailView element={field} dataSourcesList={dataSourcesList} />

        {field.elementType === 'checkList' && (
          <Fragment>
            {field.triggers?.map((trigger, triggerIndex) => (
              <div key={triggerIndex} className='d-flex gap-2 mx-1 mt-3'>
                <div
                  className={`border-green-1-h-2 border-${
                    direction === 'rtl' ? 'start' : 'end'
                  }-0 px-1 py-4`}
                ></div>
                <div className='flex-grow-1 p-2'>
                  <div className='d-flex gap-2 align-items-center'>
                    <label className='theme-size-0_9'>IF answer</label>
                    <NestedSelect
                      label={
                        isValues.find((o) => o.value === trigger.matcher)?.displayValue ?? ''
                      }
                      options={isValues.filter(
                        (o) =>
                          !field.triggers.find(
                            (t, index) =>
                              triggerIndex !== index &&
                              t.matcher === o.value &&
                              t.matchingValue === trigger.matchingValue,
                          ),
                      )}
                      handleChange={(name, value) =>
                        handleTriggerElementChange(elementIndex, triggerIndex, name, value)
                      }
                      name='matcher'
                      toggleClasses='px-1'
                    />
                    <NestedSelect
                      label={
                        options.find((o) => o.value === trigger.matchingValue)?.displayValue ??
                        'select_value'
                      }
                      options={options.filter(
                        (o) =>
                          !field.triggers.find(
                            (t, i) =>
                              i !== triggerIndex &&
                              t.matcher === trigger.matcher &&
                              t.matchingValue === o.value,
                          ),
                      )}
                      handleChange={(name, value) =>
                        handleTriggerElementChange(elementIndex, triggerIndex, name, value)
                      }
                      name='matchingValue'
                      toggleClasses='px-2 py-1 border-grey-1-h-1'
                    />
                    {trigger.matchingValue && (
                      <Fragment>
                        <label className='mx-2 theme-size-0_9'>THEN</label>
                        <div className='d-flex gap-2'>
                          {trigger.types.map(({ type }, typeIndex) => (
                            <div
                              key={typeIndex}
                              className='d-flex gap-2 theme-background-green-4 p-2 rounded-sm'
                            >
                              <img
                                className='cursor-pointer'
                                src={
                                  type === 'require_picture'
                                    ? photoIcon
                                    : type === 'require_video'
                                    ? movieIcon
                                    : messageQuestionCircleIcon
                                }
                              />
                              <label className='theme-text-green-1 theme-size-0_9'>
                                {t('text_' + type)}
                              </label>
                              <img
                                className='cursor-pointer'
                                onClick={() => {
                                  setDeleteConfirmationModal(true);
                                  setTriggerDeletionProps([
                                    elementIndex,
                                    triggerIndex,
                                    typeIndex,
                                  ]);
                                  // removeTriggerType(elementIndex, triggerIndex, typeIndex)
                                }}
                                src={crossGreyIcon}
                              />
                            </div>
                          ))}
                        </div>

                        {trigger.types.length < 3 && (
                          <NestedSelect
                            label={'+ trigger'}
                            options={triggerValues}
                            handleChange={(name, value) =>
                              handleTriggerTypeChange(name, value, triggerIndex)
                            }
                            name='type'
                            toggleClasses='px-1 py-2'
                          />
                        )}
                      </Fragment>
                    )}
                  </div>

                  <div className='child-elements'>
                    {pageElements.map((childElement, index) => (
                      <Fragment key={index}>
                        {childElement.childReferenceId &&
                          trigger.types.find(
                            (type) => type.childReferenceId === childElement.childReferenceId,
                          ) && (
                            <div className='mt-3'>
                              {childElement.elementType === 'signature' && console.log({ index })}
                              <UserFieldComponent
                                elementIndex={index}
                                field={childElement}
                                hideSwapping
                                direction={direction}
                                depth={depth + 1}
                                selectComponent={selectComponent}
                                changeFormElementOrder={changeFormElementOrder}
                                isSelected={childElement.isOpen}
                                dataSourcesList={dataSourcesList}
                                currentPage={currentPage}
                                pageElements={pageElements}
                                addTriggerInElement={addTriggerInElement}
                                handleTriggerElementChange={handleTriggerElementChange}
                                addTriggerType={addTriggerType}
                                removeTriggerType={removeTriggerType}
                                removeAllTriggers={removeAllTriggers}
                              />
                            </div>
                          )}
                      </Fragment>
                    ))}
                  </div>
                </div>
              </div>
            ))}
            {depth < 4 && options.length > 0 && (
              <div className='if' onClick={() => addTriggerInElement(elementIndex)}>
                <img src={plusIcon} />
                <label className='theme-text-green-1'>IF</label>
                <label></label>
              </div>
            )}
          </Fragment>
        )}
      </div>
    </div>
      <ConfirmationModal
        show={deleteConfirmationModal}
        title={t('alert_form_element_deletion_title')}
        message={t('alert_trigger_deletion_message')}
        actionList={[
          {
            color: 'black-1',
            text: t('button_cancel'),
            onClick: () => {
              setTriggerDeletionProps([]);
              setDeleteConfirmationModal(false);
            },
          },
          {
            color: 'red-1',
            text: t('button_delete'),
            onClick: () => {
              if (triggerDeletionProps.length) removeTriggerType(...[...triggerDeletionProps]);
              setTriggerDeletionProps([]);
              setDeleteConfirmationModal(false);
            },
          },
        ]}
      />
    </Fragment>
  );
};

UserFieldComponent.propTypes = {
  field: PropTypes.object.isRequired,
  hideSwapping: PropTypes.bool,
  direction: PropTypes.string.isRequired,
  depth: PropTypes.number.isRequired,
  selectComponent: PropTypes.func.isRequired,
  changeFormElementOrder: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  dataSourcesList: PropTypes.array.isRequired,
  elementIndex: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageElements: PropTypes.array.isRequired,
  addTriggerInElement: PropTypes.func.isRequired,
  handleTriggerElementChange: PropTypes.func.isRequired,
  addTriggerType: PropTypes.func.isRequired,
  removeTriggerType: PropTypes.func.isRequired,
  removeAllTriggers: PropTypes.func.isRequired,
};

export default UserFieldComponent;
