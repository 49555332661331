import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// import arrowDownIcon from 'assets/images/icons/arrow-down.svg';
import pageGreyIcon from 'assets/images/icons/page-grey.svg';
import NestedSelect from 'components/form-components/NestedSelect';

const PageElement = ({
  page,
  currentPage,
  pageNumber,
  totalPages,
  onClick,
  changeFormElementOrder,
}) => {
  const { t } = useTranslation();

  const options = [
    { value: 'duplicate', displayValue: 'Duplicate', disabled: false },
    { value: 'delete', displayValue: 'Delete', disabled: totalPages === 1 },
    {
      value: 'move',
      displayValue: 'Move To',
      nestedOptions: [
        { value: 'decrease', displayValue: 'Left', disabled: pageNumber === 1 },
        { value: 'increase', displayValue: 'Right', disabled: totalPages === pageNumber },
      ],
    },
  ];

  const handleChange = (name, value) => {
    if (name === 'move') {
      changeFormElementOrder('page', { position: pageNumber, totalPages, pageNumber }, value);
    } else if (value === 'delete' || value === 'duplicate') {
      changeFormElementOrder('page', { position: pageNumber, totalPages, pageNumber }, value);
    }
  };

  return (
    <div className={currentPage === pageNumber ? 'selected' : ''}>
      {/* <img
        src={arrowDownIcon}
        className={`rotate-90${pageNumber === 1 ? ' d-none' : ''}`}
        onClick={() =>
          changeFormElementOrder(
            'page',
            { position: pageNumber, totalPages, pageNumber },
            'decrease',
          )
        }
      /> */}
      <img onClick={() => onClick()} src={pageGreyIcon} />
      <label onClick={() => onClick()}>
        {page.properties?.basicProperties?.pageTitle
          ? page.properties?.basicProperties?.pageTitle
          : t('form_untitled_page')}
      </label>
      {/* <img
        src={arrowDownIcon}
        className={`rotate-270${totalPages === pageNumber ? ' d-none' : ''}`}
        onClick={() =>
          changeFormElementOrder(
            'page',
            { position: pageNumber, totalPages, pageNumber },
            'increase',
          )
        }
      /> */}
      <NestedSelect options={options} name='swap' handleChange={handleChange} />
    </div>
  );
};

PageElement.propTypes = {
  page: PropTypes.object.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  changeFormElementOrder: PropTypes.func.isRequired,
};

export default PageElement;
